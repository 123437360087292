import { BreakpointMap, PartialBreakpointMap } from './breakpoints';

export type BaselineMap = BreakpointMap<number>;

loại xuất PartialBaselineMap = PartialBreakpointMap<number>;

const defaults = {
  xs: 1.5,
  sm: 1.5,
  md: 1.5,
  lg: 1.5,
  xl: 1.5,
};

export default (input: PartialBaselineMap = {}) => ({
  ...defaults,
  ...input,
});
</number></number>