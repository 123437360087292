import { get, isNil, noop } from 'lodash';
import { Quill, RangeStatic } from 'quill';
import React from 'react';
import ArrowBox from '../ArrowBox';
import Toolbar from '../Toolbar';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';
import LinkInput from './LinkInput';
import { Link } from './ToolbarInterface';
import createToolbarItems, { EditorItem } from './createToolbarItems';

const ARROW_OFFSET = 10;

const styles = createStyles<'root' | 'measuring' | 'visible'>(theme => ({
  root: {
    display: 'inline-block',
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    transition: 'opacity 140ms 200ms',
    zIndex: 100,
  },
  visible: {
    opacity: 1,
    pointerEvents: 'auto',
    transition: 'opacity 140ms, transform 140ms',
  },
  measuring: {
    transition: 'none',
    left: '0 !important',
    top: '0 !important',
  },
}));

export interface SelectionToolbarProps {
  className?: string;
  items: Array<editoritem |="" EditorItem[]="">;
  Quill: Quill;
  forceSelect: (force?: boolean) => void;
}

loại Props = WithStyles<selectiontoolbarprops, typeof="" styles="">;

type State = {
  wrapperLeft?: number;
  wrapperTop?: number;
  arrowLeft?: number;
  visible?: boolean;
  arrowPosition?: 'up' | 'down';
  toolbarModal?: 'link';
  link?: Link;
};

const defaultProps = Object.freeze({
  forceSelect: noop,
  items: [],
});

const initialState: State = Object.freeze({ visible: false });

class SelectionToolbar extends React.Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _toolbar: HTMLElement | null = null;
  private _onLinkCancel: () => void = noop;
  private _onLinkSave: (link?: Link) => void = noop;

  public componentDidMount() {
    this._attachEventListeners();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.quill !== this.props.quill) {
      this._detachEventListeners(prevProps.quill);
      this._attachEventListeners();
    }
    if (this.state.toolbarModal !== prevState.toolbarModal) {
      this._updateToolbarPosition();
    }
  }

  public componentWillUnmount() {
    this._detachEventListeners();
  }

  public render() {
    const { classes, className } = this.props;
    const editable = this._editorIsEnabled();
    if (!this.props.quill.hasFocus() && !this.state.toolbarModal) return null;
    return (
      <div ref="{this._setToolbarRef}" className="{cx(" classes.root,="" this.state.visible="" &&="" editable="" classes.visible,="" this.state.toolbarModal="" className,="" )}="" style="{this._getWrapperStyles()}">
        <arrowbox arrowProps="{{" style:="" this._getArrowStyles()="" }}="" className="{styles.arrowBox}" direction="{this.state.arrowPosition}">
          {!this.state.toolbarModal && (
            <toolbar rounded="">
              {createToolbarItems(this.props.items, this.props.quill, this)}
            </toolbar>
          )}
          {this.state.toolbarModal === 'liên kết' && (
            <toolbar rounded="">
              <linkinput onChange="{(href," noFollow)="">
                  this.setState({ link: { href, noFollow } })
                }
                onCancelClick={this._onLinkCancel}
                onSaveClick={() => this._onLinkSave(this.state.link)}
                value={get(this.state, 'link.href', '')}
                noFollow={get(this.state, 'link.noFollow', false)}
              />
            </linkinput></toolbar>
          )}
        </arrowbox>
      </div>
    );
  }

  public createLink = () => {
    const format = this.props.quill.getFormat();
    console.log('got format:', format);
    this.props.quill.format('link', { placeholder: true }, 'api');
    this.setState(
      {
        link: format && format.link ? format.link : {},
        toolbarModal: 'link',
      },
      () => {
        this.props.forceSelect(true);
      },
    );
    this.props.quill.enable(false);
    return new Promise((resolve, reject) => {
      this._onLinkCancel = reject;
      this._onLinkSave = resolve;
    }).then(
      v => {
        this.props.forceSelect(false);
        this.setState({ toolbarModal: undefined });
        this.props.quill.enable(true);
        return v;
      },
      e => {
        this.props.forceSelect(false);
        this.setState({ toolbarModal: undefined });
        this.props.quill.enable(true);
        this.props.quill.format('link', null, 'api');
        throw e;
      },
    ) as any;
  };

  private _attachEventListeners(quill = this.props.quill) {
    quill.on('text-change', this._handleTextChange);
    quill.on('selection-change', this._handleSelectionChange);
  }

  private _detachEventListeners(quill = this.props.quill) {
    quill.off('text-change', this._handleTextChange);
    quill.off('selection-change', this._handleSelectionChange);
  }

  private _getWrapperStyles(): React.CSSProperties {
    const { wrapperLeft, wrapperTop } = this.state;
    if (isNil(wrapperLeft) && isNil(wrapperTop)) return {};
    return {
      left: `${wrapperLeft}px`,
      top: `${wrapperTop}px`,
    };
  }

  private _getArrowStyles(): React.CSSProperties {
    const { arrowLeft } = this.state;
    if (isNil(arrowLeft)) return {};
    return { left: `calc(50% + ${arrowLeft}px)` };
  }

  private _setToolbarRef = (ref: any) => {
    this._toolbar = ref;
  };

  private _handleTextChange = () => {
    if (this.state.visible) {
      this._updateToolbarPosition();
    }
  };

  private _editorIsEnabled() {
    return this.props.quill.root.contentEditable === 'true';
  }

  private _handleSelectionChange = (
    range: RangeStatic,
    oldRange: RangeStatic,
    source: string,
  ) => {
    if (range === null && oldRange !== null && source === 'user') {
      this.setState({ visible: false });
    } else if (this._editorIsEnabled() && range && source === 'user') {
      // If our selection size is greater than zero, we should show the toolbar.
      const visible = range.length >= 1;
      if (visible) this._updateToolbarPosition();
      this.setState({ visible });
    }
  };

  private _updateToolbarPosition() {
    if (!this._toolbar) return;

    const { classes } = this.props;

    // Measure the toolbar wrapper client rect. To accomplish this, we add
    // a temporary class which clears the styling so we can get an accurate
    // rectangle. This will cause a re-paint in the browser, which is less
    // than ideal, but gets the job done for now.
    this._toolbar.classList.add(classes.measuring);
    const rect = this._toolbar.getBoundingClientRect();
    this._toolbar.classList.remove(classes.measuring);

    // Try to get the current selection range.
    const range = this.props.quill.getSelection();
    if (!range) return;

    // Calculate the bounds of the quill selection.
    const bounds = this.props.quill.getBounds(range as any);

    // Calculate the "raw" left position of the toolbar, before we apply
    // any corrections to keep it in the window.
    const rawLeft = bounds.left + bounds.width / 2 - rect.width / 2;

    let wrapperTop = bounds.top - rect.height - ARROW_OFFSET;
    let arrowPosition: State['arrowPosition'] = 'down';
    if (rect.top + wrapperTop < 0) {
      wrapperTop = bounds.bottom + ARROW_OFFSET;
      arrowPosition = 'up';
    }

    // Check if our correction pushes the toolbar off the right side of the
    // window, if so, try to bring it back left without pushing the l.h.s.
    // out of the window.
    let wrapperLeft = Math.max(rawLeft - 1, -rect.left);
    const rightOverlap =
      wrapperLeft + rect.width + rect.left - window.innerWidth;
    if (rightOverlap > 0 && wrapperLeft - rightOverlap >= 0) {
      wrapperLeft = wrapperLeft - rightOverlap;
    }

    // Calculate the corrections we need to apply to keep the toolbar arrow
    // in the center of the selection.
    const toolbarCenter = wrapperLeft + rect.width / 2;
    const selectionCenter = bounds.left + bounds.width / 2;
    const arrowLeft = selectionCenter - toolbarCenter;

    // Update the object we'll use to update the component state.
    this.setState({ wrapperLeft, wrapperTop, arrowLeft, arrowPosition });
  }
}

export default withStyles(styles)(SelectionToolbar);
</props,></selectiontoolbarprops,></editoritem>