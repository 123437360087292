import React, { CSSProperties, SFC } from 'react';
import { MagnetAnchor } from '../Magnet';
import MagnetArrowBox from '../MagnetArrowBox';

type Props = {
  anchor?: MagnetAnchor;
  className?: string;
  domRef?: (el: HTMLElement | null) => void;
  style?: CSSProperties;
};

const PopupWrapper: SFC<props> = ({ trẻ em, className, ... nghỉ ngơi }) => (
  <magnetarrowbox swap="" slide="" arrowBoxClassName="{className}" {...rest}="">
    {trẻ em}
  </magnetarrowbox>
);

xuất PopupWrapper mặc định;
</props>