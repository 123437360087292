import React, { HTMLAttributes, SFC, createElement } from 'react';
import SortableItem from '../SortableItem';
import { cx } from '../utils';

/**
 * Default tag to render if one isn't specified.
 */
const DEFAULT_TAG = 'div';

/**
 * Properties for the <sortable></sortable> thành phần.
 * @property hàng Thứ tự của mục có thể sắp xếp này (do vùng chứa chính đặt).
 * @property sortClassName Class để áp dụng trong khi được sắp xếp.
 * @property sortKey Khóa sắp xếp cho mục này.
 * @property thẻ Thẻ để sử dụng khi hiển thị thành phần này.
 */
giao diện xuất SortableProps mở rộng HTMLAttributes<any> {
  disabled?: boolean;
  order?: number;
  sortClassName?: string;
  sortKey: string;
  tag: keyof JSX.IntrinsicElements;
}

/**
 * Convenience component for quickly and easily creating a sortable item.
 * @param props Component properties.
 */
const Sortable: SFC<sortableprops> = ({
  className,
  tàn tật
  sortClassName,
  sortKey,
  thẻ = DEFAULT_TAG,
  trật tự
  ... nghỉ ngơi
}) => (
  <sortableitem sortKey="{sortKey}" order="{order}" disabled="{disabled}">
    {({ domRef, onMouseDown, onTouchStart, sorting, style }) =>
      createElement(tag, {
        ...rest,
        'data-sort-key': sortKey,
        className: cx(sorting && sortClassName, className),
        onMouseDown,
        onTouchStart,
        ref: domRef,
        style: {
          ...rest.style,
          ...style,
        },
      })
    }
  </sortableitem>
);

xuất mặc định Có thể sắp xếp;
</sortableprops></any>