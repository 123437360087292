import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    textAlign: 'left',
  },
}));

export interface ListProps extends HTMLAttributes<htmldivelement> {}

loại Props = WithStyles<listprops, typeof="" styles="">;

Danh sách const: SFC<props> = ({ classes, className, theme, ... đạo cụ }) => (
  <div role="list" className="{cx(classes.root," className)}="" {...props}=""></div>
);

xuất mặc định vớiStyles (styles) (Danh sách);
</props></listprops,></htmldivelement>