import { Quill } from 'quill';
import React, { SFC } from 'react';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import ToolbarFlyout from '../ToolbarFlyout';
import { cx } from '../utils';

const editorStyles: any = {};
const styles: any = {};

export type FontOption = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export interface IFontSelectFlyoutProps {
  quill: Quill;
  options?: FontOption[];
}

const fontOptionToFormatArgsMap = {
  h1: ['header', 'H1'],
  h2: ['header', 'H2'],
  h3: ['header', 'H3'],
  h4: ['header', 'H4'],
  h5: ['header', 'H5'],
  h6: ['header', 'H6'],
  p: ['header', false],
};

const fontOptionToLabelMap = {
  h1: 'Header 1',
  h2: 'Header 2',
  h3: 'Header 3',
  h4: 'Header 4',
  h5: 'Header 5',
  h6: 'Header 6',
  p: 'Paragraph',
};

const formatToLabelMap = {
  1: fontOptionToLabelMap.h1,
  2: fontOptionToLabelMap.h2,
  3: fontOptionToLabelMap.h3,
  4: fontOptionToLabelMap.h4,
  5: fontOptionToLabelMap.h5,
  6: fontOptionToLabelMap.h6,
  default: fontOptionToLabelMap.p,
};

type FlyoutProps = {
  quill: Quill;
  options: string[];
};

const Flyout: SFC<flyoutprops> = ({ tùy chọn, quill }) => (
  <menu>
    {options.map((option: string) => (
      <menuitem key="{option}" onClick="{()" =="">
          (quill.format như bất kỳ) (
            ... (fontOptionToFormatArgsMap như bất kỳ) [tùy chọn],
            'người dùng',
          )
        }
        className={cx(editorStyles[option], styles.menuItem)}
      >
        {(fontOptionToLabelMap như bất kỳ)[option]}
      </menuitem>
    ))}
  </menu>
);

const FontFlyout: React.StatelessComponent<ifontselectflyoutprops> = ({
  options = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'],
  quill,
}) => {
  const selection = quill.getSelection();
  const header = selection && quill.getFormat().header;

  return (
    <toolbarflyout flyout="{<Flyout" quill="{quill}" options="{options}"></toolbarflyout>}>
      {(formatToLabelMap as any)[header] || formatToLabelMap.default}
    
  );
};

export default FontFlyout;
</ifontselectflyoutprops></flyoutprops>