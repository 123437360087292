import React, { SFC } from 'react';
import Ink from '../Ink';
import ToolbarItem, { ToolbarItemProps } from '../ToolbarItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'active'>(theme => ({
  root: {
    cursor: 'pointer',
    outline: 0,
    transition: 'background-color 140ms',
    borderRadius: 3,
    '&:hover': {
      backgroundColor: theme.color('grey', '50'),
    },
    '&:active': {
      compose: '$active',
    } as any,
  },
  active: {
    backgroundColor: theme.color('grey', '50'),
  },
  // TODO: disabled
}));

export interface ToolbarButtonProps extends ToolbarItemProps {
  active?: boolean;
  disabled?: boolean;
}

type Props = WithStyles<toolbarbuttonprops, typeof="" styles="">;

const Thanh công cụNút: SFC<props> = ({
  hoạt động
  trẻ em
  className,
  Lớp học
  tàn tật
  đề tài
  ... nghỉ ngơi
}) => (
  <toolbaritem className="{cx(classes.root," active="" &&="" classes.active,="" className)}="" tabIndex="{disabled" ?="" -1="" :="" 0}="" {...rest}="">
    {trẻ em}
    <ink disabled="{disabled}"></ink>
  </toolbaritem>
);

xuất mặc định vớiStyles (styles) (ToolbarButton);
</props></toolbarbuttonprops,>