import { noop } from 'lodash';
import React, { FocusEvent, PureComponent } from 'react';
import Flyout, { FlyoutProps } from '../Flyout';
import { MagnetAnchor } from '../Magnet';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

import ToolbarButton, { ToolbarButtonProps } from '../ToolbarButton';

const styles = createStyles<'root' | 'in'>(theme => ({
  root: {
    outline: 0,
    '&::after': {
      content: '""',
      borderBottom: `1px solid ${theme.color('text.body')}`,
      borderRight: `1px solid ${theme.color('text.body')}`,
      bottom: 1,
      height: 4,
      opacity: 0.3,
      position: 'absolute',
      right: 0,
      transition: 'opacity 200ms',
      width: 4,
    },
  },
  in: {
    '&::after': {
      opacity: 0,
    },
  },
}));

export interface ToolbarFlyoutProps
  extends Pick<flyoutprops, 'anchor'="" |="" 'in'="" 'flyout'="">,
    Thanh công cụButtonProps {}

loại Props = WithStyles<toolbarflyoutprops, typeof="" styles=""> &
  typeof defaultProps;

type State = { in: boolean };

const defaultProps = Object.freeze({
  anchor: 'south-left' as MagnetAnchor,
  onClick: noop,
});

const initialState = Object.freeze({ in: false });

class ToolbarFlyout extends PureComponent<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const {
      active,
      anchor,
      children,
      classes,
      className,
      flyout: flyoutComponent,
      onBlur,
      onFocus,
      in: _,
      ...rest
    } = this.props;

    const isIn = this.props.in == null ? this.state.in : this.props.in;

    return (
      <flyout anchor="{anchor}" in="{isIn}" flyout="{flyoutComponent}">
        <toolbarbutton {...rest}="" active="{active" ||="" isIn}="" className="{cx(classes.root," (active="" isIn)="" &&="" classes.in)}="" onFocus="{onFocus" =="null" ?="" this._handleFocus="" :="" onFocus}="" onBlur="{onBlur" this._handleBlur="" onBlur}="" onMouseDown="{e"> {
            if (isIOS() || isIpadOS()) {
              e.preventDefault();
              this.setState({ in: true });
            }
          }}
        >
          {children}
        </toolbarbutton>
      </flyout>
    );
  }

  private _handleFocus = (e: FocusEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ in: true });
  };

  private _handleBlur = (e: FocusEvent) => {
    e.preventDefault();
    this.setState({ in: false });
  };
}

export default withStyles(styles)(ToolbarFlyout);

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }
}

function isIpadOS() {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
}
</props,></toolbarflyoutprops,></flyoutprops,>