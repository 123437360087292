import { ButtonLabelProps } from './button-label-props';
import { ButtonText } from '../typography';
import React from 'react';
import styled from 'styled-components';

const ButtonLabelComponent = React.forwardRef<any, ButtonLabelProps="">(
  (props, ref): React.ReactElement => {
    const { color, variant, ...rest } = props;
    return <buttontext {...rest}="" ref="{ref}"></buttontext>;
  },
);

ButtonLabelComponent.displayName = 'ButtonLabel';

xuất const ButtonLabel = styled (ButtonLabelComponent) '
  Hiển thị: Flex;
  chuyển tiếp: màu 120ms;
`;
</any,>