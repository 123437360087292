import React, { HTMLAttributes, SFC } from 'react';
import List from '../List';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    boxSizing: 'border-box',
    display: 'block',
    maxWidth: '100%',
    padding: '0.3em 0',
  },
}));

export interface MenuProps extends HTMLAttributes<htmldivelement> {}

loại Props = WithStyles<menuprops, typeof="" styles="">;

const Menu: SFC<props> = ({
  trẻ em
  Lớp học
  className,
  đề tài
  ... Props
}) => (
  <div className="{cx(classes.root," className)}="" {...props}="">
    <list>{trẻ em}</list>
  </div>
);

xuất mặc định vớiStyles (styles) (Menu);
</props></menuprops,></htmldivelement>