import { OperationVariables, QueryResult } from '@apollo/react-common';
import { DocumentNode } from 'graphql';

import { QueryHookOptions } from './types';
import { useBaseQuery } from './utils/useBaseQuery';

export function useQuery<tdata =="" any,="" TVariables="OperationVariables">(
  truy vấn: DocumentNode,
  tùy chọn?: QueryHookOptions<tdata, TVariables="">
) {
  trả về useBaseQuery<tdata, TVariables="">(truy vấn, tùy chọn, false) dưới dạng QueryResult<
    TData,
    TVariables
  >;
}
</tdata,></tdata,></tdata>