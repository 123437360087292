import { Quill } from 'quill';
import React, { SFC } from 'react';
import IconMenuItem from '../IconMenuItem';
import Menu from '../Menu';
import ToolbarFlyout from '../ToolbarFlyout';

import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
} from '@robotsnacks/icons';

type AlignFlyoutProps = {
  quill: Quill;
};

type FlyoutProps = {
  quill: Quill;
};

const Flyout: SFC<flyoutprops> = ({ quill }) => (
  <menu>
    <iconmenuitem onClick="{()" ==""> quill.format('align', undefined, 'user')}>
      <formatalignleft></formatalignleft>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('căn chỉnh', 'trung tâm', 'người dùng')}>
      <formataligncenter></formataligncenter>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('căn chỉnh', 'đúng', 'người dùng')}>
      <formatalignright></formatalignright>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('căn chỉnh', 'justify', 'user')}>
      <formatalignjustify></formatalignjustify>
    </iconmenuitem>
  </menu>
);

const getCurrentAlignment = (quill: Quill) => {
  const selection = quill.getSelection();
  return (selection && quill.getFormat().align) || 'left';
};

const getAlignmentIcon = (alignment: string) => {
  switch (alignment) {
    case 'center':
      return <formataligncenter></formataligncenter>;
    Trường hợp 'đúng':
      về <formatalignright></formatalignright>;
    Trường hợp 'biện minh':
      về <formatalignjustify></formatalignjustify>;
    Trường hợp 'trái':
    Mặc định:
      về <formatalignleft></formatalignleft>;
  }
};

const AlignmentFlyoutout: SFC<alignflyoutprops> = ({ quill }) => (
  <toolbarflyout flyout="{<Flyout" quill="{quill}"></toolbarflyout>}>
    {getAlignmentIcon(getCurrentAlignment(quill))}
  
);

export default AlignmentFlyout;
</alignflyoutprops></flyoutprops>