import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    '& > *': {
      margin: '0.25em',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

export interface ButtonRowProps extends HTMLAttributes<htmldivelement> {}

loại Props = WithStyles<buttonrowprops, typeof="" styles="">;

const ButtonRow: SFC<props> = ({
  trẻ em
  Lớp học
  className,
  đề tài
  ... nghỉ ngơi
}) => (
  <div className="{cx(classes.root," className)}="" {...rest}="">
    {trẻ em}
  </div>
);

xuất mặc định vớiStyles (styles) (ButtonRow);
</props></buttonrowprops,></htmldivelement>