import { Link } from '@robotsnacks/icons';
import { noop } from 'lodash';
import React from 'react';
import Button from '../Button';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'input' | 'button'>(theme => ({
  root: {
    ...theme.font('control'),
    height: '100%',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 2px 0 6px',
  },
  input: {
    ...theme.font('control'),
    boxSizing: 'border-box',
    fontSize: 12,
    border: 0,
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    height: '100%',
    outline: 0,
    flex: 1,
    margin: '0 2px 0 5px',
    paddingLeft: 4,
    minWidth: 200,
  },
  button: {
    fontSize: 10,
    margin: 0,
  },
  label: {
    borderLeft: '1px solid #eee',
    fontSize: 10,
    padding: '5px',
  },
}));

export interface LinkInputProps {
  className?: string;
  onChange?: (value: string, noFollow?: boolean) => void;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  value?: string;
  noFollow?: boolean;
}

type Props = WithStyles<linkinputprops, typeof="" styles="">;

const defaultProps = Object.freeze({ onChange: noop });

const initialState = Object.freeze({});

class LinkInput extends React.Component<props> {
  static defaultProps = defaultProps;
  state = initialState;

  private _input: HTMLInputElement | null = null;

  public componentDidMount() {
    setTimeout(() => {
      this._input!.focus();
      this._input!.value = this.props.value || '';
    }, 10);
  }

  public render() {
    const {
      classes,
      className,
      onChange,
      onCancelClick,
      onSaveClick,
      value = '',
      theme,
      noFollow,
      ...rest
    } = this.props;

    return (
      <div className="{cx(classes.root," className)}="" {...rest}="">
        <link>
        <input onChange="{e" ==""> onChange!(e.target.value)}
          placeholder="https://example.com"
          className={classes.input}
          ref={this._setInputRef}
        />
        <button onClick="{onCancelClick}" className="{classes.button}">
          Triệt
        </button>
        <button onClick="{onSaveClick}" className="{classes.button}">
          Cứu
        </button>
        <label className="{classes.label}">
          <input type="checkbox" onChange="{e" ==""> trênThay đổi! (giá trị, ví dụ: target.checked)}
            đã chọn={noFollow}
          />
          <span style="{{" paddingLeft:="" '3px'="" }}="">Không theo dõi</span>
        </label>
      </div>
    );
  }

  private _setInputRef = (ref: any) => {
    this._input = ref;
  };
}

export default withStyles(styles)(LinkInput);
</props></linkinputprops,>