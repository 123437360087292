loại Có thể gọi được<t, U="" extends="" any[]=""> = (... args: U) => T;

Xuất mặc định <t, U="" extends="" any[]="">(f: T | Có thể gọi được<t, U="">) => (
  ...args: U
): T => {
  if (typeof f === 'function') {
    return (f as Callable<t, U="">)(... args);
  }
  trả lại f;
};
</t,></t,></t,></t,>