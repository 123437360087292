import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    lineHeight: 2,
    padding: '0 0.5em',
  },
}));

export interface ListItemProps extends HTMLAttributes<htmldivelement> {}

loại Props = WithStyles<listitemprops, typeof="" styles="">;

const ListItem: SFC<props> = ({ classes, className, theme, ... đạo cụ }) => (
  <div role="listitem" className="{cx(classes.root," className)}="" {...props}=""></div>
);

xuất mặc định vớiStyles (styles) (ListItem) dưới dạng SFC<listitemprops>;
</listitemprops></props></listitemprops,></htmldivelement>