import { ObservableQuery } from 'apollo-client';
import { QueryOptions } from '../types';
import { DocumentNode } from 'graphql';
import { QueryData } from '../data/QueryData';

type QueryInfo = {
  seen: boolean;
  observable: ObservableQuery<any, any=""> | null;
};

function makeDefaultQueryInfo(): QueryInfo {
  return {
    seen: false,
    observable: null
  };
}

export class RenderPromises {
  // Map from Query component instances to pending fetchData promises.
  private queryPromises = new Map<queryoptions<any, any="">Hứa<any>>();

Ánh xạ hai lớp từ (tài liệu truy vấn, các biến được xâu chuỗi) đến QueryInfo
  Đối tượng. Các đối tượng QueryInfo này được dự định để tồn tại trong toàn bộ
  quy trình getMarkupFromTree, trong khi các phiên bản Truy vấn cụ thể không tồn tại
  ngoài một lệnh gọi duy nhất để hiển thị ToStaticMarkup.
  truy vấn riêngInfoTrie = Bản đồ mới<documentnode, Map<string,="" QueryInfo="">>();

Đăng ký phía máy chủ hiển thị có thể quan sát được.
  đăng ký công khaiSSRObservable<tdata, TVariables="">(
    observable: ObservableQuery<any, TVariables="">,
    Đạo cụ: QueryOptions<tdata, TVariables="">
  ) {
    this.lookupQueryInfo(props).observable = observable;
  }

  // Get's the cached observable that matches the SSR Query instances query and variables.
  public getSSRObservable<tdata, TVariables="">(
    Đạo cụ: QueryOptions<tdata, TVariables="">
  ) {
    return this.lookupQueryInfo(props).observable;
  }

  public addQueryPromise<tdata, TVariables="">(
    queryInstance: QueryData<tdata, TVariables="">,
    finish: () => React.ReactNode
  ): React.ReactNode {
    const info = this.lookupQueryInfo(queryInstance.getOptions());
    if (!info.seen) {
      this.queryPromises.set(
        queryInstance.getOptions(),
        new Promise(resolve => {
          resolve(queryInstance.fetchData());
        })
      );
      // Render null to abandon this subtree for this rendering, so that we
      // can wait for the data to arrive.
      return null;
    }
    return finish();
  }

  public hasPromises() {
    return this.queryPromises.size > 0;
  }

  public consumeAndAwaitPromises() {
    const promises: Promise<any>[] = [];
    this.queryPromises.forEach((promise, queryInstance) => {
      // Make sure we never try to call fetchData for this query document and
      // these variables again. Since the queryInstance objects change with
      // every rendering, deduplicating them by query and variables is the
      // best we can do. If a different Query component happens to have the
      // same query document and variables, it will be immediately rendered
      // by calling finish() in addQueryPromise, which could result in the
      // rendering of an unwanted loading state, but that's not nearly as bad
      // as getting stuck in an infinite rendering loop because we kept calling
      // queryInstance.fetchData for the same Query component indefinitely.
      this.lookupQueryInfo(queryInstance).seen = true;
      promises.push(promise);
    });
    this.queryPromises.clear();
    return Promise.all(promises);
  }

  private lookupQueryInfo<tdata, TVariables="">(
    Đạo cụ: QueryOptions<tdata, TVariables="">
  ): QueryInfo {
    const { queryInfoTrie } = this;
    const { query, variables } = props;
    const varMap = queryInfoTrie.get(query) || new Map<string, QueryInfo="">();
    if (!queryInfoTrie.has(query)) queryInfoTrie.set(query, varMap);
    const biếnString = JSON.stringify (biến);
    const info = varMap.get(variablesString) || makeDefaultQueryInfo();
    if (!varMap.has(variablesString)) varMap.set(variablesString, thông tin);
    thông tin trả lại;
  }
}
</string,></tdata,></tdata,></any></tdata,></tdata,></tdata,></tdata,></tdata,></any,></tdata,></documentnode,></any></queryoptions<any,></any,>