import { MagnetAnchor } from '../Magnet';
import { overRefs } from '../utils';
import FlyoutTransition from './FlyoutTransition';
import FlyoutWrapper from './FlyoutWrapper';

import React, {
  Component,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

type FlyoutChildProps = {};

export interface FlyoutProps {
  anchor?: MagnetAnchor;
  children?:
    | ReactElement<flyoutchildprops>
    | ((props: FlyoutChildProps & { flyout: ReactNode }) => ReactNode);
  flyout: ReactNode;
  in?: boolean;
}

export type Props = FlyoutProps & typeof defaultProps;

type State = {};

const defaultProps = Object.freeze({
  in: false,
});

const initialState = {};

export default class Flyout extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _domRef: HTMLElement | null = null;

  render() {
    const { children } = this.props;
    const props = {};
    const flyout = this._renderFlyout();
    if (typeof children === 'function') {
      return children({ ...props, flyout });
    } else if (isValidElement(children)) {
      return cloneElement(children, props, children.props.children, flyout);
    }
    return null;
  }

  private _renderFlyout = () => {
    const { anchor } = this.props;
    return (
      <flyouttransition in="{this.props.in}" mountOnEnter="" onEnterDone="{this._handleEnterDone}" onExitActive="{this._handleExitActive}" unmountOnExit="">
        {({ domRef, ... nghỉ ngơi }) => (
          <flyoutwrapper in="{this.props.in}" elevation="{5}" anchor="{anchor}" domRef="{overRefs(domRef," this._setDOMRef)}="" {...rest}="">
            {this.props.flyout}
          </flyoutwrapper>
        )}
      </flyouttransition>
    );
  };

  private _setDOMRef = (el: HTMLElement | null) => {
    this._domRef = el;
  };

  private _handleEnterDone = () => {
    if (this._domRef) {
      this._domRef.dispatchEvent(
        new CustomEvent('taffy:flyout:opened', { bubbles: true }),
      );
    }
  };

  private _handleExitActive = () => {
    if (this._domRef) {
      this._domRef.dispatchEvent(
        new CustomEvent('taffy:flyout:closing', { bubbles: true }),
      );
    }
  };
}
</props,></flyoutchildprops>