import { noop } from 'lodash';
import { createContext } from 'react';

export type ResizeAnchor =
  | 'north'
  | 'south'
  | 'east'
  | 'west'
  | 'north-west'
  | 'north-east'
  | 'south-west'
  | 'south-east';

export type StartResizeParams = {
  anchor?: ResizeAnchor;
  restrict?: 'x' | 'y';
};

export interface ResizeContextType {
  endResize: () => void;
  startResize: (
    e: React.MouseEvent<any> | React.TouchEvent<any>,
    params: StartResizeParams,
  ) => khoảng trống;
}

xuất mặc định createContext<resizecontexttype>({
  endResize: noop,
  startResize: noop,
});
</resizecontexttype></any></any>