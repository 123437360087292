import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background.paper'),
    borderRadius: 4,
    boxShadow: [
      `0 0 8px ${theme.color('grey', '100')}`,
      `1px 1px 2px ${theme.color('grey', '200')}`,
    ].join(','),
    display: 'flex',
    flexDirection: 'column',
    fontSize: 0,
    '& > *': {
      fontSize: '1rem',
    },
  },
}));

export interface CardProps extends HTMLAttributes<htmldivelement> {
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<cardprops, typeof="" styles="">;

Thẻ const: SFC<props> = ({ className, classes, theme, domRef, ... nghỉ ngơi }) => (
  <div className="{cx(classes.root," className)}="" {...rest}="" ref="{domRef}"></div>
);

xuất mặc định vớiStyles (styles) (Thẻ);
</props></cardprops,></htmldivelement>