import { map, max, range } from 'lodash';
import React, { Component, HTMLAttributes, createRef } from 'react';
import GridEngine from '../GridEngine';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx, random } from '../utils';

export type GridView = {
  columns?: number;
  columnWidth: string;
  engine: GridEngine;
  media: string;
  rowHeight: string;
  rows?: number;
};

const styles = createStyles<'root' | 'sentinel' | 'column' | 'row'>(() => ({
  root: {},
  sentinel: {
    display: 'none',
  },
  column: {},
  row: {},
}));

const GRID_ID_LENGTH = 8;

export interface GridProps extends HTMLAttributes<htmldivelement> {
  views?: GridView[];
  measure?: boolean;
}

type Props = WithStyles<gridprops, typeof="" styles="">;

/**
 *
 */
lớp Grid mở rộng Thành phần<props> {
  private _defaultId = `grid_${random(GRID_ID_LENGTH)}`;
  private _el = createRef<htmldivelement>();

  render() {
    const {
      children,
      classes,
      className,
      views,
      measure,
      theme,
      ...rest
    } = this.props;
    return (
      <div className="{cx(classes.root," className)}="" {...rest}="" id="{this._getId()}" ref="{this._el}">
        {this._renderStyle()}
        {trẻ em}
        {this._renderSentinels()}
      </div>
    );
  }

  getMeasurements() {
    const { classes } = this.props;
    const { current } = this._el;
    if (current) {
      const columns = current.querySelectorAll(
        `.${classes.sentinel}.${classes.column}`,
      );
      const rows = current.querySelectorAll(
        `.${classes.sentinel}.${classes.row}`,
      );
      return {
        columns: map(columns, c => c.clientWidth),
        rows: map(rows, r => r.clientHeight),
      };
    }
    return { rows: [], columns: [] };
  }

  getDomElement() {
    return this._el.current;
  }

  private _renderStyle(pretty: boolean = false) {
    const { classes } = this.props;
    const delimiter = pretty ? '\n' : '';
    const gridId = this._getId();
    const css = map(
      this.props.views,
      ({ columnWidth, media, engine, rowHeight }) => {
        // Get the actual number of columns and rows from the engine.
        const cols = engine.columns;
        const rows = engine.rows;
        return [
          `@media ${media} {`,
          `#${gridId} {`,
          `display: -ms-grid;`,
          `display: grid;`,
          `-ms-grid-columns: 0 (${columnWidth})[${cols}];`,
          `grid-template-columns: 0 repeat(${cols}, ${columnWidth});`,
          `-ms-grid-rows: 0 (minmax(70px, max-content))[${rows}];`, // HACK: Super-hack for IE11... force the rows to 70px.
          `grid-template-rows: 0 repeat(${rows}, ${rowHeight});`,
          '}',
          ...engine
            .getAllItems()
            .map(item => {
              const { row, column, height, id, width } = item;
              const endRow = row + height + 2;
              const endCol = column + width + 2;
              return [
                `#${id} {`,
                `-ms-grid-row: ${row + 2};`,
                `-ms-grid-column: ${column + 2};`,
                `-ms-grid-row-span: ${height};`,
                `-ms-grid-column-span: ${width};`,
                `grid-area: ${row + 2}/${column + 2}/${endRow}/${endCol};`,
                `}`,
              ].join(delimiter);
            })
            .toArray(),
          ...range(0, cols).map(c =>
            [
              `#${gridId} .${classes.column}-${c} {`,
              'display: block;',
              '}',
            ].join(delimiter),
          ),
          ...range(0, this.props.measure ? rows : 0).map(r =>
            [`#${gridId} .${classes.row}-${r} {`, 'display: block;', '}'].join(
              delimiter,
            ),
          ),
          '}',
        ].join(delimiter);
      },
    );
    return <style dangerouslySetInnerHTML="{{" __html:="" css.join('\n')="" }}=""></style>;
  }

  /**
   * Renders measurement sentinel elements which are used to measure the height
   * of each row and the width of each column.
   */
  private _renderSentinels() {
    const { classes } = this.props;

    if (!this.props.measure) return null;

    // Always render the maximum number of column sentinels that exist in the
    // grid view, or the grid engine. This ensure that we have at least as
    // many column sentinels as there are actual columns.
    const maxC = max(
      map(this.props.views, view => max([view.engine.columns, view.columns])),
    );
    const maxR = max(map(this.props.views, view => view.engine.rows));

    // Create the row sentinels. The grid row they're placed at is offset by
    // 2, since we're starting at a zero count (and grids are 1-based) we add
    // 1, and since the first row is zero-height, don't place one there (that's
    // the row where column sentinels are placed).
    const rowSentinels = range(0, maxR).map(r => (
      <span key="{r}" className="{cx(classes.sentinel," classes.row,="" `${classes.row}-${r}`)}="" style="{{" gridColumn:="" '1="" span="" 1',="" gridRow:="" `${r="" +="" 2}="" 1`="" }}=""></span>
    ));

Kết xuất các lính gác cột theo cách tương tự như lính gác hàng.
    const columnSentinels = range(0, maxC).map(c => (
      <span key="{c}" className="{cx(" classes.sentinel,="" classes.column,="" `${classes.column}-${c}`,="" )}="" style="{{" gridColumn:="" `${c="" +="" 2}="" span="" 1`,="" gridRow:="" `1="" 1`="" }}=""></span>
    ));
    return (
      <>
        {columnSentinels}
        {rowSentinels}
      </>
    );
  }

  private _getId(): string {
    return this.props.id || this._defaultId;
  }
}

export default withStyles(styles)(Grid);
</htmldivelement></props></gridprops,></htmldivelement>