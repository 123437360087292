import React, { SFC } from 'react';
import ListSection, { ListSectionProps } from '../ListSection';

export type GridListSectionProps = ListSectionProps;

const GridListSection: SFC<gridlistsectionprops> = đạo cụ => (
  <listsection {...props}=""></listsection>
);

xuất GridListSection mặc định;
</gridlistsectionprops>