import { filter, head, keys, map, reduce } from 'lodash';
import { Quill } from 'quill';
import React from 'react';
import ToolbarSeparator from '../ToolbarSeparator';
import AlignFlyout from './AlignmentFlyout';
import FontFlyout from './FontFlyout';
import FormatButton from './FormatButton';
import LinkButton from './LinkButton';
import ListFormatButton from './ListFormatButton';
import RedoButton from './RedoButton';
import UndoButton from './UndoButton';

import { ToolbarInterface } from './ToolbarInterface';

export type EditorItemKey =
  | 'add-comment'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align'
  | 'attachment'
  | 'blockquote'
  | 'bold'
  | 'clear-format'
  | 'code'
  | 'dedent'
  | 'embed'
  | 'fill-color'
  | 'font-select'
  | 'font-size'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'highlight-color'
  | 'image'
  | 'indent'
  | 'italic'
  | 'link'
  | 'ordered-list'
  | 'p'
  | 'redo'
  | 'separator'
  | 'strike-through'
  | 'text-color'
  | 'underline'
  | 'undo'
  | 'unordered-list'
  | 'video';

export type EditorItem = EditorItemKey | Partial<{ [K in EditorItemKey]: any }>;

const createEditorItem = (
  item: EditorItem,
  index: number,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const key: string | EditorItemKey =
    typeof item === 'string' ? item : (head(keys(item)) as EditorItemKey);

  // FIXME: fix any cast.
  const options = typeof item === 'string' ? null : (item as any)[key];

  switch (key) {
    case 'bold':
    case 'italic':
    case 'underline':
    case 'strike-through':
    case 'blockquote':
      return <formatbutton key="{key}" format="{key}" quill="{quill}"></formatbutton>;

Trường hợp 'dấu phân cách':
      về <toolbarseparator key="{index}"></toolbarseparator>;

Trường hợp 'căn chỉnh':
      về <alignflyout key="{key}" quill="{quill}"></alignflyout>;

Trường hợp 'hoàn tác':
      về <undobutton key="{key}" quill="{quill}"></undobutton>;

Trường hợp 'làm lại':
      về <redobutton key="{key}" quill="{quill}"></redobutton>;

Trường hợp 'chọn phông chữ':
      về <fontflyout key="{key}" quill="{quill}" options="{options}"></fontflyout>;

Trường hợp 'liên kết':
      về <linkbutton key="{key}" quill="{quill}" toolbar="{toolbar}"></linkbutton>;

Trường hợp 'danh sách đặt hàng':
    Trường hợp 'danh sách không có thứ tự':
      về <listformatbutton key="{key}" format="{key}" quill="{quill}"></listformatbutton>;

Trường hợp 'thêm-bình luận':
    Trường hợp 'File đính kèm':
    Trường hợp 'định dạng rõ ràng':
    Trường hợp 'mã':
    Trường hợp 'Dedent':
    Trường hợp 'nhúng':
    Trường hợp 'tô màu':
    Trường hợp 'kích thước phông chữ':
    Trường hợp 'màu nổi bật':
    Trường hợp 'hình ảnh':
    Trường hợp 'thụt lề':
    Trường hợp 'màu văn bản':
    Trường hợp 'video':

Trường hợp 'Align-Center':
    Trường hợp 'căn chỉnh-biện minh':
    Trường hợp 'căn trái':
    Trường hợp 'Align-Right':

Trường hợp 'H1':
    Trường hợp 'H2':
    Trường hợp 'H3':
    Trường hợp 'H4':
    Trường hợp 'H5':
    Trường hợp 'H6':

Trường hợp 'P':
      ném Lỗi mới ('Không được triển khai.');
  }
};

xuất mặc định (
  items: Mảng<editoritem |="" EditorItem[]="">,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const reduced = reduce(
    items,
    (acc: EditorItem[], item: EditorItem | EditorItem[]) => {
      if (Array.isArray(item)) {
        return acc.concat(['separator', ...item, 'separator']);
      }
      return acc.concat([item]);
    },
    [] as any,
  );

  const filtered = filter(reduced, (item, i: any, array) => {
    // Don't show separator as first/last items.
    if (i === 0 || i === array.length - 1) return item !== 'separator';
    // Don't show consecutive separators.
    if (item === 'separator' && array[i - 1] === 'separator') return false;
    return true;
  });

  return map(filtered, (item, i) => createEditorItem(item, i, quill, toolbar));
};
</editoritem>