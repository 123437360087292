import { useContext, useEffect, useReducer, useRef } from 'react';
import {
  getApolloContext,
  OperationVariables,
  QueryResult
} from '@apollo/react-common';
import { DocumentNode } from 'graphql';

import { QueryHookOptions, QueryOptions, QueryTuple } from '../types';
import { QueryData } from '../data/QueryData';
import { useDeepMemo } from './useDeepMemo';

export function useBaseQuery<tdata =="" any,="" TVariables="OperationVariables">(
  truy vấn: DocumentNode,
  tùy chọn?: QueryHookOptions<tdata, TVariables="">,
  lazy = false
) {
  const context = useContext(getApolloContext());
  const [tick, forceUpdate] = useReducer(x => x + 1, 0);
  const updatedOptions = options ? { ...options, query } : { query };

  const queryDataRef = useRef<querydata<tdata, TVariables="">>();

  if (!queryDataRef.current) {
    queryDataRef.current = new QueryData<tdata, TVariables="">({
      options: updatedOptions as QueryOptions<tdata, TVariables="">,
      context,
      forceUpdate
    });
  }

  const queryData = queryDataRef.current;
  queryData.setOptions(updatedOptions);
  queryData.context = context;

  // `onError` and `onCompleted` callback functions will not always have a
  // stable identity, so we'll exclude them from the memoization key to
  // prevent `afterExecute` from being triggered un-necessarily.
  const memo = {
    options: { ...updatedOptions, onError: undefined, onCompleted: undefined },
    context,
    tick
  };

  const result = useDeepMemo(
    () => (lazy ? queryData.executeLazy() : queryData.execute()),
    memo
  );

  const queryResult = lazy
    ? (result as QueryTuple<tdata, TVariables="">)[1]
    : (kết quả là QueryResult<tdata, TVariables="">);

  useEffect(
    () => queryData.afterExecute({ lazy }),
    lazy
      ? undefined
      : [
          queryResult.loading,
          queryResult.networkStatus,
          queryResult.error,
          queryResult.data
        ]
  );

  useEffect(() => {
    return () => queryData.cleanup();
  }, []);

  return result;
}
</tdata,></tdata,></tdata,></tdata,></querydata<tdata,></tdata,></tdata>